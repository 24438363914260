import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Menu from "./components/navbar/Menu";
import Cover from "./components/cover/Cover";
import Footer from "./components/footer/Footer";
import Mailer from "./components/mailer/Mailer";
import Revista from "./components/revista/Revista";
import About from "./components/about/About";
import Promo from "./components/promo/PromoComponent";
import Portfolio from"./components/slider/Portfolio";
import CarouselComponent from './components/Carousel/CarouselComponent';
import ReferenciasComponent from "./components/ReferenciasComponent";
import PaymentMethodsComponent from "./components/PaymentMethodsComponent";
import Dashboard from "./components/Dashboard/Dashboard";

const references = [
  {
    avatarUrl: "././img/imgC2.jfif",
    title: "Juan Alvarez",
    description: "Muy recomendable! en una semana tenia lista mi pagina web!"
  },
  {
    avatarUrl:"././img/imgC2.jfif",
    title: "Silvana Cuenca",
    description: "Antes no tenia presencia en línea, pero ahora estoy muy satisfecha con la calidad de su trabajo!"
  },
  {
    avatarUrl: "././img/imgC2.jfif",
    title: "Graciela  Moreno",
    description:"Muy buena atencion y relacion precio calidad!",
  
  }
];
const paymentMethods = [
  {
    name: "Tarjetas de crédito o débito",
    description: "Puede pagar con tarjeta de crédito o débito.",
    imageUrl: "././img/tarjetas.jfif",
  },
  {
    name: "Mercado Pago",
    imageUrl: "././img/mp.jfif",
    description: "Puede pagar con Mercado Pago con tarjetas o en efectivo."
  },
  {
    imageUrl: "././img/bancos.jfif",
    name: "Transferencia bancaria",
    description: "O puede realizar una transferencia bancaria por cbu o alias."
  }
];

const App = () => {
  return (
    <>
    <BrowserRouter>
    <Menu />
   
    
        <Routes>
      
            <Route path='/Nosotros' element={<About/>}/> 
            <Route path='/Trabajos' element={<Portfolio/>}/>
            <Route path='/promo' element={<Promo/>}/>
            <Route path='/Contacto' element={<Mailer/>}/> 
            <Route path='/Redes' element={<Footer/>}/> 
            <Route path='/Dashboard' element={<Dashboard/>}/> 
          </Routes>
          <Cover />
          <About/>
          
         <Revista/>
      
         <Promo />
          <CarouselComponent />
          <ReferenciasComponent references={references} />
          <PaymentMethodsComponent paymentMethods={paymentMethods} />

          <Mailer />
         
          <Footer />
    </BrowserRouter>
      </>
  );
}

export default App;
