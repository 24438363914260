import React from "react";
import styled from "styled-components";

// Estilo del contenedor principal
const PromoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 26px 0;
 

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

// Estilo del título
const Title = styled.div`
  margin-bottom: 20px;
  text-align: center; /* Centrar el texto del título */

  h2 {
    font-size: 20px;
    color: #0c57fb;
  }
`;

// Estilo de promoción
const PromoCard = styled.div`
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 300px;

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: black;
  }

  p {
    color: #555;
  }

  .precio{
   
    margin-top: 20px;
    h2 {
      font-size: 20px;
      color: #0c57fb;
    }
    p {
      color: #555;
      font-weight: bold;
    }
  }
  


  @media (min-width: 768px) {
    margin-right: 20px;
    width: calc(33.33% - 20px); /* Ajuste para que tres cuadros entren en una fila */
    max-width: 300px; /* Añadir un ancho máximo para asegurar que no se vuelvan demasiado grandes */
  }
`;

const PromoComponent = () => {
  return (
    <PromoContainer>
      <Title>
      <h2>Servicios</h2>
      </Title>
      <PromoCard>
        <h3>DISEÑO WEB SIMPLE- SINGLE PAGE</h3>
        <p>
          Ideal para quienes están empezando y necesitan una página web estática ,para
          presentarse, mostrar sus servicios o productos en internet.
        </p>
        <div className="precio">
          <h3>Alojamiento incluido por 1 año</h3>
          <h3>Dominio incluido por 1 año</h3>
          <h3>Seguridad SSL incluido por 1 año</h3>
           <h3>Unico pago</h3>
          <p>De $ 90.000</p>
           <h3>O 2 pagos</h3>
          <p>De $ 55.000</p>
        </div>
      </PromoCard>
      <PromoCard>
        <h3>DISEÑO WEB AUTOGESTIONABLE</h3>
        <p>
          Para proyectos más grandes o rediseños. Muestra más detalles sobre tu
          formación, experiencia laboral, marca y detalle servicios en tu sitio
          web
        </p>
        <div className="precio">
          <h3>Alojamiento incluido por 1 año</h3>
          <h3>Dominio incluido por 1 año</h3>
          <h3>Seguridad SSL incluido por 1 año</h3>
             <h3>Unico pago</h3>
          <p>De $ 230.000</p>
       
             <h3>O 2 pagos</h3>
          <p>De $ 125.000</p>
        </div>
      </PromoCard>
      <PromoCard>
        <h3>DISEÑO WEB TIENDA ONLINE</h3>
        <p>
          Para tiendas online y/o marcas que producen contenido y servicios y
          quieren dar a conocer aún más sus servicios.incluye carrito de compras
        </p>
        <div className="precio">
          <h3>Alojamiento incluido por 1 año</h3>
          <h3>Dominio incluido por 1 año</h3>
          <h3>Seguridad SSL incluido por 1 año</h3>
          <div className='consulta'>
              <h3>Unico pago</h3>
          <p>De $ 250.000</p>
       
             <h3>O 2 pagos</h3>
          <p>De $ 1
          35.000</p>
         </div>
        </div>
      </PromoCard>
    </PromoContainer>
  );
};

export default PromoComponent;
