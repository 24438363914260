import React from 'react'
import InisioSesion from './InicioSesion';
import Registro from './Registro';
const Dashboard = () => {
  return (
    <div className='dash'>
  <InisioSesion />
     
      < Registro />
    </div>
  )
}

export default Dashboard